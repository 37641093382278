.highlight-tabs {
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: 0;
	padding: 0;
	&-wrapper {
		.tab-content {
			padding: 0;
			margin-bottom: 1rem;
		}
		.tab-pane {
			background-color: #fff;
			padding: 25px;
			padding-bottom: 2rem;
			box-shadow: 0px 0px 12px -2px #c6d0cd;
			border-top-right-radius: 2rem;
			border-top-left-radius: 2rem;
			border-bottom-right-radius: 2rem;
			border-bottom-left-radius: 2rem;
		}
		.tab-pane.first {
			border-top-left-radius: 0;
		}
	}
	.nav-link {
		height: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: none;
		border: 1px solid #dee2e6;
		margin-right: 1rem;
		background-color: rgba(138, 216, 179, 0.039);
		border-radius: 1rem;
	}
	.nav-link.active {
		position: relative;
		height: 5rem;
		border-bottom: 0;
		box-shadow: 0px 0px 12px -2px #c6d0cd;
		border-color: #fff;
		background-color: #fff;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		&:after {
			background-color: #fff;
			height: 15px;
			content: "";
			position: absolute;
			bottom: -15px;
			left: 0;
			right: -15px;
		}
	}
}
.product-view {
	&-tab {
		margin-bottom: 0;
		border-top-right-radius: 0.5rem;
		border-top-left-radius: 0.5rem;
		min-height: 4rem;
		.nav-link {
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}
		&-wrapper {
			margin-top: 3rem;
			border-top-right-radius: 0.5rem;
			border-top-left-radius: 0.5rem;
		}
		& + div.tab-content {
			padding: 0;
		}
	}
	&-sub-tab {
		&-wrapper {
			display: flex;
			align-items: flex-start;
			@media (max-width: 800px) {
				flex-direction: column;
			}
		}
		&-content {
			flex: 5;
			padding-top: 2rem;
			border: 1px solid #dee2e6;
			border-top: 0;
			&-header {
				display: flex;
				justify-content: space-between;
				margin-right: 2rem;
			}
		}
		&-image {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 2;
			border: 1px solid #dee2e6;
			border-top: 0;
			margin-left: 1rem;
			&.show-md {
				display: none;
				margin-left: 0;
				width: 100%;
			}
			&.show-lg {
				display: flex;
			}
			@media (max-width: 800px) {
				&.show-md {
					display: flex;
				}
				&.show-lg {
					display: none;
				}
			}
		}
	}
}
.view-tabs {
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: 0;
	padding: 0;
	.nav-link {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 10rem;
		height: 3.5rem;
		border: 1px solid #dee2e6;
		border-bottom: none;
		margin-right: 0.4rem;
		background-color: #ecf1f0;
	}
	.nav-link.active {
		position: relative;
		height: 3.5rem;
		border-bottom: 0;
		border-bottom-color: #fff;
		background-color: #fff;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		&:after {
			background-color: #fff;
			height: 15px;
			content: "";
			position: absolute;
			bottom: -15px;
			left: 0;
			right: -15px;
			width: 100%;
		}
	}
	& + div.tab-content {
		padding: 0;
		border-top: 1px solid #dee2e6;
		.tab-pane {
			background-color: #fff;
			padding: 3rem 25px 25px;
		}
	}
}

// Image Viewer
.product-seeds-image {
	&-slider {
		padding: 1rem;
		width: 100%;
	}
	&-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 15rem;
		width: 100%;
		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: contain;
		}
	}
	&-indicator {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		button {
			font-size: 20px;
			border-radius: 50%;
			padding: 0;
			width: 3rem;
			height: 3rem;
			margin: 10px;
			color: #006241;
		}
	}
}
.mw-pv-data {
	min-width: 17rem;
}

.header-common-container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	.header-logo-container {
		padding: 6px 6px 6px 40px;
		width: 220px;
		height: 80px;
		background-color: #ffffff !important;
		border-bottom-left-radius: 47px;
		a {
			display: flex;
			align-items: center;
			height: 100%;
			.logo-dimension {
				width: 150px;
				// height: 50px;
				// padding: 6px 6px 6px 30px;
			}
		}
	}
	.header-menu-container {
		height: 80px;
		padding: 6px;
		flex: 3;
		background-color: #006241 !important;
		border-bottom-right-radius: 47px;
		background: url("../../assets/images/header-pattern.svg");
		display: flex;
		align-items: center;
		.navbar-expand-lg {
			flex: 1;
		}
		.navbar-nav {
			padding: 0 20px;

			// Center Navbar Items
			&.navbar-menu-center {
				.nav-link {
					padding: 0 20px;
					// Adjust "dropdown-menu" if below padding is adjusted
					@media screen and (max-width: 1100px) {
						padding: 0 17px;
					}
					@media screen and (max-width: 1060px) {
						padding: 0 14px;
					}
					@media screen and (max-width: 1024px) {
						padding: 0 10px;
					}
				}
				// Navbar items with dropdown
				.navbar-menu-link {
					color: #ffffff !important;
					font-size: 13px;
					font-weight: 500;
				}
				.dropdown-menu {
					top: 150%;
					left: 20px;
					padding: 0;

					.menu-border {
						border-bottom: 1px solid #e9ecef;
						padding: 0.75rem 1.5rem;

						.dropdown-menu {
							.dropdown-item {
								padding: 0.75rem 1.5rem;
								border-bottom: 1px solid #e9ecef;
								&:last-child {
									border-bottom: none;
								}
							}
						}
						&:last-child {
							border-bottom: none;
						}
						&.dropdown {
							padding: 0.5rem 0;
						}
					}
					@media screen and (max-width: 1100px) {
						left: 17px;
					}
					@media screen and (max-width: 1060px) {
						left: 14px;
					}
					@media screen and (max-width: 1024px) {
						left: 10px;
					}
				}
			}

			// Common Navlink styles
			.nav-link {
				color: #ffffff;
				font-size: 13px;
				position: relative;
				font-weight: 500;
				&:before {
					content: "";
					display: block;
					opacity: 0;
					width: 30%;
					height: 3px;
					left: 35%;
					bottom: -10px;
					position: absolute;
					background-color: var(--color-light-green);
					transition: var(--default-transition);
				}
				&.no-underline::before {
					display: none;
				}
				&.active:before {
					opacity: 1;
					width: 60%;
					left: 20%;
				}
				&:after {
					display: none;
				}
			}
		}
		// Dropdown menu item arrow
		.navbar-menu-down-arrow {
			margin-left: 10px;
			font-size: 9px;
		}

		// Dropdowns
		.dropdown-menu {
			border: none;
			box-shadow: 0 0 7px -1px rgba(73, 80, 87, 0.699);
			.dropdown-item-wrapper {
				padding: 0.5rem 0;
				border-bottom: 1px solid #e9ecef;
			}
			.dropdown-item {
				font-size: 12px;

				&:focus,
				&:hover {
					background-color: transparent;
					color: #00a73b;
				}
				&.active,
				&:active {
					color: #fff;
					background-color: #006241;
				}
			}

			.inner-submenu {
				.dropdown-toggle {
					font-weight: 400;
					color: #212529;
					font-size: 12px;
					padding: 0.25rem 1.5rem;

					&:focus,
					&:hover {
						background-color: transparent;
						color: #00a73b;
					}
					&.active,
					&:active {
						color: #fff;
						background-color: #006241;
					}
				}
				.dropdown-menu {
					top: 1px;
					left: 104%;
				}
			}
		}

		// Collapsed stage
		@media screen and (max-width: 990px) {
			.brightfarms-navbar {
				justify-content: flex-end;
			}
			.navbar-collapse {
				&::-webkit-scrollbar {
					appearance: none;
					width: 0px;
				}
				.navbar-nav {
					padding: 0;
					// align-items: flex-start;

					&.navbar-menu-center {
						.nav-link {
							padding: 15px 10px;
							&:before {
								width: 100%;
								left: 0px;
								bottom: 0;
								margin-bottom: 4px;
							}
						}
						.navbar-menu-link {
							&:before {
								display: none;
							}
						}
					}
					.dropdown-menu {
						background-color: #e1e8e624;
						.dropdown-item {
							color: #fff;
							&:focus,
							&:hover {
								background-color: transparent;
								color: #10d25e;
							}
							&.active,
							&:active {
								color: #fff;
								background-color: #006241;
							}
						}
						.dropdown-divider {
							border-top: 1px solid #e9ecef38;
						}
					}
					.dropdown.nav-item.show {
						.nav-link {
							// background-color: #e1e8e624;
							border-top-right-radius: 10px;
							border-top-left-radius: 10px;
						}
					}
				}
			}
		}

		// Navbar items at right end
		.secondry-nav-class {
			padding: 0 0 0 10px;
			align-items: center;
			.nav-link {
				padding: 0 20px;
			}
			.notification-count {
				position: absolute;
				top: -9px;
				right: 6px;
				border-radius: 50%;
				text-align: center;
				min-width: 18px;
				min-height: 18px;
				padding: 3px;
				background-color: #c9dd74;
				font-size: 10px;
				font-weight: bold;
				color: #484646;
			}
			.dropdown-menu {
				left: auto;
				right: 18px;
				top: 120%;
			}
			.profile-nav-dropdown .dropdown-menu {
				position: absolute;
			}
			.thumbnail-image {
				background-color: #d8d8d8;
				border-radius: 50%;
				width: 34px;
				height: 34px;
				overflow: hidden;
				object-fit: cover;
				box-shadow: 0 5px 10px rgba(#000000, 0.3);
			}
			.icon-notification {
				font-size: 24px;
				width: 19px;
				height: 25px;
			}
		}
	}
}

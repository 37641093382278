.location-listing-col-name {
	flex: 3;
}
.location-listing-col-city {
	flex: 2;
}
.location-listing-col-state {
	flex: 2;
}
.location-listing-col-ponds {
	flex: 1;
}
.location-listing-col-wastage {
	flex: 1;
}
.location-listing-col-products {
	flex: 1;
}
.location-listing-col-users {
	flex: 1;
}
.location-listing-col-actions {
	.dropdown-toggle::after {
		display: none;
	}

	width: 120px;
	justify-content: flex-end;
	text-align: right;
}

.text-green {
	color: rgb(0, 160, 106);
}

.location-users-table {
	&-head {
		display: flex;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		font-weight: 700;
		color: #527977;
		background-color: #e2e7e7;
		padding: 15px;
	}
	&-body {
		display: flex;
		padding: 15px;
		border: 1px solid #e2e7e7;
		border-top: 0;
	}
	&-cell {
		font-size: 14px;
		padding-left: 3px;
		&.cell-checkbox {
			flex: 1;
		}
		&.cell-name {
			flex: 5;
		}
		&.cell-role {
			flex: 4;
		}
	}
	&-drop {
		button {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: inherit;
			padding: 0;
		}
	}
	&-input {
		width: 80%;
		background-color: rgba(255, 255, 255, 50%);
		border: none;
		border-radius: 3px;
		margin-right: 5px;
		outline: none;
		font-size: 12px;
		padding: 3px;
		&:focus,
		&:hover,
		&:active {
			outline: none;
		}
	}
}
.sort-priority-container {
	.sort-priority-container-child {
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		min-height: 50px;
		background-color: #ecf1f0;
		border: 1px solid #ececec;
		margin-bottom: 10px;
		p {
			margin-top: 10px;
		}
	}
}
.table-body-wrapper {
	overflow-x: hidden;
	overflow-y: auto;
	margin-top: 0px;
	padding: 10px 5px 10px 5px;
	.modal-table-body-row {
		box-shadow: 2 -1px 3px 0px #ececec;
	}
}

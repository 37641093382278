.droparea-thumbnails {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 5px -5px;
	.thumbnail {
		margin: 5px;
		width: 50px;
		height: 50px;
		background-color: #eee;
		border-radius: 5px;
		overflow: hidden;
		img {
			width: 50px;
			height: 50px;
			object-fit: contain;
		}
	}
}
.droparea {
	display: block;
	width: 100%;
	border: 1px dashed #ddd;
	background-color: #eee;
	border-radius: 5px;
	cursor: pointer;
	&.over {
		border: 1px dashed rgb(79, 196, 124);
		background-color: rgba(79, 196, 124, 0.2);
	}
	input[type="file"] {
		display: none;
	}
	.droparea-placeholder {
		display: block;
		text-align: center;
		padding: 5px 0 15px 0;
		font-size: 12px;
		color: #666;
	}
}

.image-upload {
	&-wrapper {
		display: flex;
        width: 100%;
        flex-wrap: wrap;
	}
	&-input {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 7rem;
		width: 11rem;
		cursor: pointer;
		border-radius: 5px;
		border: 1px dashed rgb(79, 196, 124);
		background-color: #eee;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	&-placeholder {
		display: block;
		text-align: center;
		font-size: 13px;
		color: #666;
	}
	&-thumbnails {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		background-color: #eee;
		height: 7rem;
		width: 11rem;
		object-fit: cover;
		margin-right: 10px;
		.remove-btn {
			position: absolute;
			border-radius: 50%;
			padding: 0px;
			font-size: 10px;
			line-height: 0;
			top: -7px;
			right: -8px;
			width: 24px;
			height: 25px;
			color: #006241;
			background-color: #fff;
			box-shadow: 0px 0px 3px 2px #c6d0cd;
			.icon {
				margin-right: 0 !important;
				line-height: 0 !important;
			}
		}
		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: cover;
		}
	}
	input[type="file"] {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.sku-listing-col-name {
	flex: 2;
}
.sku-listing-col-number {
	flex: 1;
}
.sku-listing-col-location {
	flex: 2;
}
.sku-listing-col-actions {
	justify-content: flex-end;
	text-align: right;
}
.sku-location-status {
	display: flex;
	justify-content: space-between;
}

.nav-tabs {
	background-color: #ecf1f0;
	padding: 0px 20px;
	margin-bottom: 25px;
	.nav-link {
		border: none;
		&.active {
			background-color: transparent;
			border-bottom: 3px solid #006241;
		}
	}
	.nav-item {
		font-weight: 600;
		color: #006241 !important;
		padding: 13px 35px;
	}
}
.tab-headers {
	background-color: #ffffff;
	border-radius: 4.5px;
	border: 1px solid #ecf1f0;
	margin-bottom: 10px;
}
.tab-content {
	padding: 0px 25px;
}
.modal-footer {
	border-top: none;
}
.seeds-listing-col {
	&-name {
		flex: 3;
	}
	&-price {
		flex: 2;
	}
	&-density {
		flex: 2;
	}
	&-actions {
		width: 130px;
		justify-content: flex-end;
		text-align: right;
	}
}

.harvest-listing-col {
	&-checkbox {
		width: 35px;
		height: 29px;
		&-header {
			width: 35px;
			height: auto;
		}
	}
	&-name {
		flex: 2;
	}
	&-dc {
		flex: 2;
	}
	&-city {
		flex: 2;
	}
	&-actions {
		width: 120px;
		justify-content: center;
		text-align: right;
	}
}

.outer-box {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	//overflow: hidden;
	padding: 1rem;
	margin-bottom: 1rem;
}
.outer-box-view {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	padding: 2rem;
	margin-bottom: 1rem;
	.status-bar-subtitle {
		font-size: 12px;
		margin-bottom: 0;
		margin-top: 5px;
	}
}
.remove-link-btn {
	font-size: 13px;
	font-weight: 500;
	color: #6d6e71;
	.icon {
		font-size: 10px;
	}
	&:hover,
	&:active,
	&:focus {
		color: #6d6e71;
	}
}

.add-button {
	.btn {
		width: 140px;
	}
}
// EDIT PAGE

.harvest-board-outer > .harvest-board-row {
	background-color: #f2f5f4;
	padding: 10px;
	margin: 25px 0 0 0;
	border-radius: 5px;
	&:first-child {
		margin: 10px 0 0 0;
	}
}
.harvest-board-add-row {
	background-color: #f2f5f4;
	padding: 10px 25px 25px;
	margin-top: -5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.harvest-board-add {
	background-color: #f2f5f4;
	outline: none;
	width: 100%;
	padding: 5px;
	border: 1px dashed #d9e6e1;
	color: #4e917b;
	border-radius: 5px;
	&:focus {
		border: 1px dashed #d9e6e1;
		outline: none;
	}
}

.harvest-board-delete {
	background-color: #f2f5f4;
	width: 30px;
	padding: 2px 2px 0;
	border: 1px dashed #d9e6e1;
	color: #4e917b;
	border-radius: 5px;
	&:focus {
		border: 1px dashed #d9e6e1;
		outline: none;
	}
}
.bright-farms-table-td.harvest-listing-col-actions {
	min-width: 130px;
}

.bright-farms-table-th.harvest-listing-col-actions.harvest-listing-col-actions-header {
	min-width: 130px;
}
.view-top-table {
	margin-top: 1.5rem;
}

.light-border {
	border: 1px solid #e1e8e6;
}

.table-col1 {
	max-width: 185px;
}
.transplantcol {
	flex: 2 0 !important;
}
.table-col2 {
	flex: 2;
}
.table-col3 {
	flex: 3;
	overflow: unset !important;
}
.start-task-row {
	height: 70px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.bright-farms-table-order {
	display: flex;
	width: 100%;
	flex-direction: column;
	animation: table-anim 0.4s ease-in-out;
}
.bright-farms-table-col-group-order {
	display: table-column-group;
}
.bright-farms-table-col-order {
	display: table-column;
}
.bright-farms-table-thead-order {
	margin-bottom: 10px;
	span {
		font-weight: 500 !important;
	}
	.bright-farms-table-tr-order {
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		text-transform: capitalize;
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		min-height: 50px;
		background-color: #ecf1f0;
		border: 1px solid #ececec;
	}
	.bright-farms-table-th:first-child {
		border-radius: 5px 0 0 5px;
	}
	.bright-farms-table-th:last-child {
		border-radius: 0 5px 5px 0;
	}
}

.bright-farms-table-tbody-order {
	display: flex;
	flex-direction: column;
}
.bright-farms-table-tr-order {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	transition: all 0.1s ease-in-out;
	min-height: 50px;
	background-color: #fff;
	border: 1px solid #ececec;
	border-radius: 10px;
	color: #6d6e71;
	font-size: 14px;
	font-weight: 400;
	&:hover {
		box-shadow: 0 2px 4px rgba(0, 98, 65, 0.28);
	}
	.bright-farms-table-td:first-child {
		border-radius: 5px 0 0 5px;
	}
	.bright-farms-table-td:last-child {
		border-radius: 0 5px 5px 0;
	}
}
.bright-farms-table-tr-order {
	display: flex;
	flex: 1;
	//margin: 0 0 0 0;
	align-items: center;
}
.bright-farms-table-th-order {
	display: flex;
	padding: 10px 15px;
	letter-spacing: 0px;

	&.break-word {
		white-space: inherit !important;
	}
}
.bright-farms-table-td-order {
	display: block;
	padding: 5px 15px;
}
.bright-farms-table-th,
.bright-farms-table-td {
	&:not(.nowrap) {
		// white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.harvestLogForm {
	.view-form-input-disabled {
		.custom-select-box,
		.form-control:disabled {
			background-color: #fff;
			opacity: 0.9;
		}
	}
}

.top-harvesting-checkbox {
	left: 16px;
	right: 17px;
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) {
	   top: 35px !important;
	}
	@media only screen 
	and (min-device-width: 1000px) 
	and (max-device-width: 1330px) {
	   top: 28px !important;
	}
}

.position-btn {
	padding: 0 5px;
	min-height: 44px !important;
	height: auto !important;
	
}

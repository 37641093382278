// Seeding germination listing page
@mixin sg-listing-table-common-style {
	@media (max-width: 1440px) {
		font-size: 14px;
		&-header {
			font-size: 14px;
		}
	}
}
.sg-listing-col {
	&-checkbox {
		width: 35px;
		height: 29px;
		&-header {
			width: 35px;
			height: auto;
		}
	}
	&-taskId {
		flex: 1;
		@media (max-width: 1440px) {
			flex: none;
			width: 105px;
		}
		@include sg-listing-table-common-style;
	}
	&-pv {
		flex: 2;
		@include sg-listing-table-common-style;
	}
	&-date {
		flex: 2;
		@include sg-listing-table-common-style;
		&-header {
			overflow: unset !important;
		}
	}
	&-number {
		flex: 0.9;
		@include sg-listing-table-common-style;
		&-header {
			text-transform: none;
		}
	}
	&-flag {
		flex: 1.3;
		@include sg-listing-table-common-style;
	}
	&-assignee {
		flex: 2;
		@include sg-listing-table-common-style;
	}
	&-status {
		flex: 1.5;
		@media (max-width: 900px) {
			flex: none;
			width: 115px;
		}
		@include sg-listing-table-common-style;
	}
	&-actions {
		width: 130px;
		justify-content:center;
		text-align: right;
	}
}

.listing-checkbox {
	left: 16px;
	top: 17px;
}

.btn-dropdown {
	border: 2px solid #e1e8e6;
	background-color: #fff;
	margin-right: 1rem;
	border-radius: 5px;
	.dropdown-item {
		border-bottom: 1px solid #e1e8e6;
		padding: 0.7rem 1.5rem;
		&:last-of-type {
			border: none;
		}
	}
	.dropdown-item.active,
	.dropdown-item:active {
		color: #fff;
		text-decoration: none;
		background-color: #006241;
	}
	.dropdown-item:focus {
		outline-color: rgba(0, 98, 65, 0.596);
	}
}

// Seeding germination view page
.sg-view {
	&-content {
		border: 1px solid #e1e8e6;
		border-radius: 4.5px;
		padding: 1rem 2rem;
		margin-bottom: 1rem;
		&.status-bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.status-bar-title {
				font-weight: bold;
				margin: auto 0;
			}
			.status-bar-subtitle {
				font-size: 12px;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}
		&.pt-no-head {
			padding-top: 2.3rem;
		}
	}
	&-footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;
		padding-bottom: 2rem;
	}
}

// Daily Status Modal
.daily-tasks-alert {
	color: #006241;
	font-style: italic;
	font-weight: 500;
	background-color: rgba(225, 232, 230, 0.149);
	font-size: 13px;
}

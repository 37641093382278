.bright-farms-wrap {
	height: 100%;
	.bright-farms-container {
		height: 100%;
		.content-wrap {
			padding: 20px;
			.content-container {
				background-color: #fff;
				border-radius: 30px;
				padding: 25px;
				background-image: url(./assets/content-container-bg.svg);
				background-repeat: no-repeat;
				background-position: right top;
			}
		}
	}
}

.bright-farms-calendar {
	.react-datepicker__header {
		text-align: center;
		background-color: #e1e8e6;
	}
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected,
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__month-text--selected,
	.react-datepicker__month-text--in-selecting-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--selected,
	.react-datepicker__quarter-text--in-selecting-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--selected,
	.react-datepicker__year-text--in-selecting-range,
	.react-datepicker__year-text--in-range {
		background-color: #006241;
	}
	.react-datepicker__day--keyboard-selected:hover,
	.react-datepicker__month-text--keyboard-selected:hover,
	.react-datepicker__quarter-text--keyboard-selected:hover,
	.react-datepicker__year-text--keyboard-selected:hover {
		background-color: rgba(0, 98, 65, 0.699);
	}
	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 28px;
		line-height: 28px;
		font-size: 13px;
	}
	.react-datepicker__input-time-container {
		font-size: 13px;
	}
}

.custom-toggle-btn {
	display: inline-block;
	background-color: var(--color-light-gray);
	width: 40px;
	height: 20px;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	.toggle-btn {
		width: 14px;
		height: 14px;
		background-color: #fff;
		display: block;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 3px;
		left: 3px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
	&.active {
		background-color: var(--color-theme);
		.toggle-btn {
			left: 23px;
		}
	}
}

.modal.modal-sidepanel {
	&[style*="display: block;"] {
		display: flex !important;
		justify-content: flex-end;
	}
	.modal-dialog {
		height: 100%;
		margin: 0;
		.modal-header {
			background-color: var(--color-theme);
			border-top-left-radius: 40px;
			background-image: url("./assets/images/header-pattern.svg");

			display: flex;
			justify-content: flex-end;
			font-size: 16px;
			font-weight: 600;
			color: var(--color-white);
			height: 60px;
			.modal-title {
				padding: 6px;
				font-size: 16px;
				font-weight: 600;
				color: var(--color-white);
				text-align: left;
				flex: 1;
				margin-left: 10px;
			}
			.close {
				display: none;
			}
		}
		.modal-content {
			min-height: 100%;
			width: 495px;
			border-radius: 40px 0 0 40px;
			background-color: transparent;
			border: none;
			.form-group {
				margin-bottom: 0.5rem;
			}
			.modal-body,
			.modal-footer {
				background-color: var(--color-white);
				border-radius: 0 0 0 40px;
			}
			.modal-footer {
				border-bottom-left-radius: 40px;
			}
		}
	}
}

.modal.modal-confirm {
	.modal-dialog {
		max-width: 400px;
		.modal-content {
			border: none;
			padding: 20px;
		}
	}
}
.bright-farms-modal-close-button {
	border: none;
	background-color: var(--color-light-gray);
	border-radius: 50%;
	line-height: 0;
	padding: 7px;
	.icon {
		font-size: 8px;
		color: var(--color-theme);
	}
}

@media (max-width: 992px) {
	.navbar-light .navbar-toggler {
		color: #fff;
		border: none;
		padding: 0;
		outline: none;
		.custom-navbar-toggler {
			width: 27px;
		}
	}
	.header-common-container .header-menu-container .secondry-nav-class {
		padding: 30px 0 0 10px;
		align-items: center;
		flex-direction: row;
	}
	.navbar-collapse {
		position: fixed;
		top: 80px;
		left: 0px;
		background-color: rgb(68, 68, 68);
		z-index: 10;
		width: 100%;
		align-items: flex-start;
		justify-content: flex-end;
		border-radius: 40px;
		padding: 30px;
		max-height: calc(100% - 70px);
		overflow: auto;
	}
}

.table-loader-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.671);
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-border.text-primary {
	color: #006241 !important;
}

.spinner-button {
	position: relative;
	.spinner-border {
		position: absolute;
		top: -1px;
		left: -1px;
	}
}

.custom-y-scroll {
	/* width */
	&::-webkit-scrollbar {
		width: 5px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #006241;
	}
}

.scroll-invisible {
	&::-webkit-scrollbar {
		appearance: none;
		width: 0px;
	}
}
.custom-scroll {
	overflow-y: auto !important;
	/* width */
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		-webkit-appearance: none;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		border-radius: 5px;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 5px;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #636466;
	}
}

.italic-text {
	font-size: 13px;
	color: #6d6e71;
	font-style: italic;
}

// Breadcrumbs
.page-bread-crumbs {
	font-size: 12px;
	font-weight: 500;
	color: #000000;
	cursor: default;
	label {
		color: #006241;
		font-size: 12px;
		font-weight: 500;
	}
	a {
		font-size: 12px;
		font-weight: 500;
		color: #000000;
		&:hover {
			color: #35984c;
		}
	}
}

// TODO : Check below classes
// Found in multiple files
.text-13px {
	font-size: 13px;
}
.view-h2 {
	margin-bottom: 20px;
}
.form-label {
	// font-size: 12px !important;
	color: #6d6e71 !important;
}
::placeholder {
	color: #afafaf !important;
}
.form-control {
	border-radius: 4.5px;
	border: 1px solid #cdcece;
	height: 39px;
}
.form-control {
	&:focus {
		box-shadow: none !important;
	}
}
.dc-block {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	overflow: hidden;
	padding: 10px;
}
.btn-link {
	font-size: 13px;
	font-weight: 500;
	color: #006242;
	.icon {
		font-size: 10px;
	}
}
.form-headers {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;
	padding-left: 15px;
}

.profile-round-upload {
	.image-upload-input,
	.image-upload-thumbnails {
		width: 80px !important;
		height: 80px !important;
	}
	.image-upload-placeholder {
		font-size: 10px;
	}
}
.break-word {
	word-break: break-word;
}
.back-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 14px;
	margin-right: 20px;
	.icon {
		font-size: 9px;
		margin-right: 5px;
	}
}

// EXPANDABLE react select style

.react-select-expand__control::-webkit-scrollbar,
.react-select-expand__menu-list::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	-webkit-appearance: none;
}

.react-select-expand__control::-webkit-scrollbar-thumb,
.react-select-expand__menu-list::-webkit-scrollbar-thumb {
	background: #cccccc;
	border-radius: 5px;
}

.react-select-expand__control::-webkit-scrollbar-track,
.react-select-expand__menu-list::-webkit-scrollbar-track {
	border-radius: 5px;
}

.react-select-expand-btn {
	position: absolute;
	top: -19px;
	background: #fff;
	right: 5px;
	border: 1px solid #ced4da;
	border-bottom: none;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px 5px 0 0;
	cursor: pointer;

	.icon-angle-down,
	.icon-angle-up {
		font-size: 10px;
	}
}
.react-select-expand__multi-value__remove:hover {
	border-radius: 0 7px 7px 0;
}

.react-select-expand__control {
	z-index: 3;
}

.select-abs {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;

	.form-label {
		max-width: calc(100% - 25px);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.react-select-expand__menu {
	z-index: 100 !important;
}

.react-select-expand__option--is-selected {
	background-color: #e1e8e6 !important;
	color: #6d6e71 !important;
}

.react-select-expand__placeholder {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// EXPANDABLE react select style  ENDS

.multiple-entry-box__multi-value__remove {
	display: block !important;
}

//export button height set
.btn-fixed-height{
	height: 42px;
}

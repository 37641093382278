.profile-view-modal {
	&-backdrop {
		background-color: transparent;
	}
	.modal-dialog {
		margin: 80px 1rem auto auto;
		max-width: 340px;
	}
	.modal-content {
		border: none;
		box-shadow: 0px 0px 15px 1px rgba(73, 80, 87, 0.28);
		border-radius: 18px;
		background-image: url(../../assets/content-container-bg.svg);
		background-repeat: no-repeat;
		background-position: right top;
		background-size: 130px;
	}
	.modal-body {
		padding: 2rem;
	}
	.text-profile {
		&-label {
			font-size: 12px;
		}
		&-value {
			font-size: 13px;
		}
	}
	&-image {
		width: 5rem;
		height: 5rem;
		margin-bottom: 1.5rem;
	}
}
.profile-edit-image {
	.image-upload-thumbnails {
		background-color: #ffffff;
		border: 1px solid #eee;
		height: 8rem;
		width: 8rem;
	}
}

@mixin transplant-listing-table-common-style {
	@media (max-width: 1440px) {
		font-size: 13px;
		&-header {
			font-size: 13px;
		}
	}
}
.transplant-listing-col {
	&-checkbox {
		width: 35px;
		height: 29px;
		&-header {
			width: 35px;
			height: auto;
		}
	}
	&-taskId {
		flex: 1;
		@media (max-width: 1440px) {
			flex: none;
			width: 100px;
		}
		@include transplant-listing-table-common-style;
	}
	&-taskId-header {
		flex: 1;
		@media (max-width: 1440px) {
			flex: none;
			width: 100px;
		}
		@include transplant-listing-table-common-style;
	}
	&-pv {
		flex: 1.5;
		@include transplant-listing-table-common-style;
	}
	&-pv-header {
		flex: 1.5;
		@include transplant-listing-table-common-style;
	}
	&-date {
		flex: 2;
		@include transplant-listing-table-common-style;
		&-header {
			overflow: unset !important;
			flex: 2;
		}
	}

	&-pond {
		flex: 1;
		@include transplant-listing-table-common-style;
	}
	&-lane {
		flex: 1;
		@include transplant-listing-table-common-style;
	}

	&-assignee {
		flex: 2;
		@include transplant-listing-table-common-style;
	}
	&-status {
		flex: 1;
		@media (max-width: 1440px) {
			flex: none;
			width: 115px;
		}
		@include transplant-listing-table-common-style;
	}
	&-actions {
		width: 130px;
		justify-content: center;
		text-align: right;
	}
}
.outer-box {
	background: #f2f5f4;
}

.table-scroll {
	max-height: 15rem;
	overflow-x: scroll;
	overflow-y: scroll;
}
.border-box {
	border-radius: 4.5px;
	border: 1px dashed #e1e8e6;
	//overflow: hidden;
	//padding: 0.5rem;
	margin-bottom: 1rem;
}
.outer-box {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	//overflow: hidden;
	padding: 1rem;
	margin-bottom: 1rem;
}
.transplant-view-wrapper {
	.table-wrap {
		border-radius: 4.5px;
		border: 1px solid #e1e8e6;
	}
	table {
		overflow-y: auto !important;
		table-layout: fixed;
		word-wrap: break-word;
		word-break: break-all;

		width: 100%;
		td {
			overflow: hidden;
		}
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
			-webkit-appearance: none;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			border-radius: 5px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #cccccc;
			border-radius: 5px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #636466;
		}
	}

	.table-header-color {
		background: #ebf0ef;
		font-weight: 500;
		font-size: 13px;
	}
	.text-muted {
		font-size: 12px;
		color: #6d6e71;
	}
	.pill-text {
		font-weight: 500;
	}
}
.pond-parameter-select {
	width: 19rem;
	margin-right: 1rem;

	.header-react-select__control {
		min-height: 44px;
		border-width: 2px;
		border-color: #e1e8e6;
		box-shadow: none;
		&:hover {
			border-color: #e1e8e6;
		}
	}
	.header-react-select__option {
		background-color: transparent;
		color: inherit;
	}
	.header-react-select__indicator-separator {
		display: none;
	}
	.header-react-select__placeholder,
	.header-react-select__single-value {
		color: #212529;
		font-weight: 500;
	}
	.header-react-select__indicator.header-react-select__clear-indicator {
		padding: 0;
		cursor: pointer;
	}
	.header-react-select__indicator.header-react-select__dropdown-indicator {
		padding-left: 0;
		padding-right: 5px;
	}

	.header-react-select-common__control {
		min-height: 40px;
		&:hover {
			border-color: #e1e8e6;
		}
	}

	.header-react-select-common__indicator-separator {
		display: none;
	}
	.header-react-select-common__placeholder,
	.header-react-select-common__single-value {
		color: #212529;
		font-weight: 400;
	}
	.header-react-select-common__indicator.header-react-select-common__clear-indicator {
		padding: 0;
		cursor: pointer;
	}
	.header-react-select-common__indicator.header-react-select-common__dropdown-indicator {
		padding-left: 0;
		padding-right: 5px;
	}
	.header-react-select__value-container{
		padding: 8px !important;
	}
	// .css-g1d714-ValueContainer{
	// 	padding: 8px 8px !important;
	// }
}
.pond-edit-values {
	background-color: var(--color-white);
	border-radius: 5px;
	border: 1px solid var(--color-light-gray);
	margin-left: 0 !important;
	padding: 0.7rem;
}
.datewidth{
	flex: 2.7 1 !important;
}
.transplantbtn{
	padding: 0 5px;
	min-height: 44px !important;
	height: auto !important;
}
.top-transplant-checkbox{
	left: 16px;
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) {
	   top: 35px !important;
	}
	@media only screen 
	and (min-device-width: 1000px) 
	and (max-device-width: 1220px) {
	   top: 27px !important;
	}

}
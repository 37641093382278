.checkboxWrapper {
	display: block;
	position: relative;
	// padding-left: 21px;
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover {
		input ~ .checkboxMark {
			background-color: rgba(201, 221, 116, 0.7);
		}
		input:disabled ~ .checkboxMark {
			background-color: #ecf1f0;
		}
	}
	input:checked ~ .checkboxMark {
		background-color: rgba(201, 221, 116, 0.6);
	}
	input:disabled ~ .checkboxMark {
		background-color: #ecf1f0;
	}
	&.disabled {
		cursor: default;
	}
}

/* Hide the browser's default checkbox */
.checkboxWrapper input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	&:disabled {
		cursor: default;
	}
}

/* Create a custom checkbox */
.checkboxMark {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	border: 0.5px solid #ecf1f0;
	background-color: rgba(201, 221, 116, 0.5);
	&:after {
		content: "";
		position: absolute;
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
		display: block;
	}
}

/* Show the checkmark when checked */
.checkboxWrapper input:checked ~ .checkboxMark:after {
	display: block;
	opacity: 1;
}

/* Style the checkmark/indicator */
.checkboxWrapper {
	.checkboxMark:after {
		border: solid #35984c;
	}
	.checkboxMark.normal:after {
		left: 5px;
		top: 1px;
		width: 6px;
		height: 12px;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	// Single stroke Checked style
	.checkboxMark.single:after {
		left: 7px;
		top: 3px;
		width: 0;
		height: 10px;
		border-width: 0 2px 0 0;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
.checkboxWrapper input:disabled ~ .checkboxMark:after {
	border-color: #aaaaaa;
}

.tooltip-checkbox-wrapper {
	display: block;
	position: relative;
	padding-left: 21px;
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	user-select: none;
}

.tooltip-checkbox-wrapper input:checked ~ .tooltip-checkbox-mark:after {
	display: block;
	opacity: 1;
	background-color: #ccc;
}

.tooltip-checkbox-wrapper input {
	cursor: pointer;
	// opacity: 0;
	// height: 0;
	// width: 0;
	display: none;
}

.tooltip-checkbox-mark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 30px;
	border-radius: 50%;
	width: 25px;

	border: 0.5px solid #ecf1f0;

	.tooltip-checkbox-wrapper input:checked {
		opacity: 1;
		background-color: #ccc;
	}

	&:after {
		background-color: #fff;
		border: 1px solid #ccc;
		content: "";
		/* content: "\e908"; */
		background-image: url(/add-to-package.svg);
		background-repeat: no-repeat;
		background-position: center;
		text-align: center;
		vertical-align: middle;
		display: inline-flex;
		transition: all 0.2s ease-in-out;
		/* left: 50%; */
		position: relative;
		/* top: 50%; */
		/* transform: translate(-41%, -37%); */
		width: 100%;
		height: 100%;
	}
}

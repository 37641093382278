.reports-container-climate {
	font-family: "Poppins", sans-serif;
	.align-text-report-center {
		text-align: center;
	}
	.recharts-wrapper {
		min-height: 500px;
	}
	.react-datepicker__month-wrapper {
		white-space: nowrap;
	}

	.recharts-default-tooltip {
		.recharts-tooltip-item-list {
			max-height: 300px !important;
			overflow-y: auto !important;
			/* width */
			&::-webkit-scrollbar {
				width: 7px;
				height: 7px;
				-webkit-appearance: none;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				border-radius: 5px;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #cccccc;
				border-radius: 5px;
			}
			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #636466;
			}
		}
	}
	.back-button-reports {
		border-radius: 9.5px;
		border: 1px solid #636466;
		background-color: #ffffff;
		padding: 0 20px;
		display: inline-block;
		height: 30px;
	}
	// .recharts-layer > .recharts-bar-rectangle {
	// 	cursor: pointer !important;
	// }
	.recharts-legend-wrapper {
		font-size: 12px;
		padding-left: 8%;
		overflow-y: auto !important;
		/* width */
		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
			-webkit-appearance: none;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			border-radius: 5px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #cccccc;
			border-radius: 5px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #636466;
		}
		span {
			cursor: pointer !important;
			margin-left: 1px !important;
		}
	}
	.tool-tip-label {
		font-size: 10px;
		font-weight: 500;
		color: #43425d;
		font-family: "Poppins", sans-serif;
	}
	.recharts-default-tooltip {
		margin: 0px;
		padding: 10px;
		background-color: rgb(255, 255, 255);
		border: 1px solid rgb(204, 204, 204);
		white-space: nowrap;
	}
	.recharts-tooltip-label {
		margin: 0px;
		font-size: 10px;
		font-family: Poppins, sans-serif;
		font-weight: 500;
		color: rgb(67, 66, 93);
	}
	.recharts-tooltip-item-list {
		padding: 0px;
		margin: 0px;
	}
	.recharts-tooltip-item {
		display: block;
		color: rgb(67, 66, 93);
		font-size: 10px;
		font-family: Poppins, sans-serif;
		font-weight: 500;
	}
	.chart-grid-class {
		background-color: #ffffff;
		border-radius: 9.5px;
		border: 1px solid #f0f0f0;
		overflow: hidden;
		.customized-legend {
			padding: 5px;
			margin: 5px;
			text-align: center;
			font-size: 12px;
			color: #4d4f5c;
			font-weight: 500;
		}
	}
	.legend-single-details-class {
		font-size: 12px;
		font-weight: 500;
		color: #000000;
	}
	.recharts-tooltip-wrapper {
		.custom-tooltip {
			line-height: 16px !important;
			border: 1px solid #f5f5f5;
			background-color: rgba(255, 255, 255, 0.8);
			padding: 10px;

			.label {
				margin: 0;
				color: #666;
				font-weight: 700;
			}
			.x-value {
				margin: 3px;
				//color: #666;
				font-size: 12px;
				font-weight: 500;
				color: #43425d;
				text-align: center !important;
			}
			.desc {
				margin: 0;
				color: #999;
			}
			.intro {
				border-top: 1px solid #f5f5f5;
				margin: 0;
			}
		}
	}

	.margin-top-10 {
		margin-top: 1.5rem;
	}
}

.modal-body {
	.thumbnail-image {
		width: 64px;
		height: 64px;
		background-color: #d8d8d8;
		border-radius: 50%;
		overflow: hidden;
	}
	.image-upload-input,
	.image-upload-thumbnails {
		background-color: #e1e8e6;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		img {
			border-radius: 50%;
			width: 100px;
			height: 100px;
		}
	}
	.vector-image {
		background-color: #e1e8e6;
		border-radius: 50%;
		width: 64px;
		height: 64px;
		overflow: hidden;
		display: table;
		.icon-user {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			margin: 0 auto;
			color: #b9c4c1;
			font-size: 35px;
		}
	}
	::placeholder {
		color: #afafaf;
	}
	.user-id-class {
		font-size: 12px;
		color: #6d6e71;
		label {
			font-weight: 500;
			color: #212529;
		}
	}

	.user-name-class {
		font-size: 18px;
		font-weight: 600;
	}
	.user-flex-class {
		flex: 1;
	}

	.cursor-point {
		cursor: initial;
	}
	.toggler-status-text {
		font-size: 12px;
		font-weight: 500;
		color: #35984c;
		margin-left: 19px;
		margin-top: 5px;
	}
}

.dashboard {
	.recharts-legend-item {
		font-size: 12px;
	}
	.content-container {
		background-color: unset !important;
		background-image: unset !important;
		padding: 5px 20px 20px 20px !important;
	}
	.name-class {
		padding-top: 1rem;
	}
	.header-react-select__clear-indicator {
		display: none;
	}
	.count-blocks {
		.col-xl-2,
		.col-lg-3,
		.col-md-4,
		.col-sm-4,
		.col-4 {
			padding-left: 2px;
		}
		.card {
			background-color: #fff;
			border-radius: 10px;
			margin-right: 10px;
			flex: fill;

			.graph-headers {
				font-size: 14px;
				color: rgba(0, 0, 0, 1);
				font-weight: bold;
			}
			.card-text {
				//white-space: nowrap;
				text-align: left;
				font-weight: normal;
				font-size: 12px;
				color: #464646;
			}
			.card-text-empty {
				text-align: center;
				font-weight: normal;
				font-size: 16px;
				color: #464646;
				padding: 20%;
			}
		}
	}
}

// .color {
// 	--paper-red-300: #e57373;
// 	--paper-purple-300: #ba68c8;
// 	--paper-deep-purple-400: #7e57c2;
// 	--paper-indigo-500: #3f51b5;
// 	--paper-blue-500: #2196f3;
// 	--paper-green-600: #43a047;
// 	--paper-red-900: #b71c1c;
// 	--paper-pink-a700: #c51162;
// 	--paper-deep-purple-800: #4527a0;
// 	--paper-indigo-900: #1a237e;
// 	--paper-red-500: #f44336;
// 	--paper-green-900: #1b5e20;
// 	--paper-teal-a700: #00bfa5;
// 	--paper-amber-500: #ffc107;
// 	--paper-light-blue-a200: #40c4ff;
// 	--paper-blue-grey-700: #455a64;
// 	--paper-cyan-a700: #00b8d4;

// 	--paper-red-400: #ef5350;

// 	--paper-red-600: #e53935;
// 	--paper-red-700: #d32f2f;
// 	--paper-red-800: #c62828;
// 	--paper-red-900: #b71c1c;
// 	--paper-red-a100: #ff8a80;
// 	--paper-red-a200: #ff5252;
// 	--paper-red-a400: #ff1744;
// 	--paper-red-a700: #d50000;

// 	--paper-pink-400: #ec407a;
// 	--paper-pink-500: #e91e63;
// 	--paper-pink-600: #d81b60;
// 	--paper-pink-700: #c2185b;
// 	--paper-pink-800: #ad1457;
// 	--paper-pink-900: #880e4f;
// 	--paper-pink-a100: #ff80ab;
// 	--paper-pink-a200: #ff4081;
// 	--paper-pink-a400: #f50057;
// 	--paper-pink-a700: #c51162;

// 	--paper-purple-400: #ab47bc;
// 	--paper-purple-500: #9c27b0;
// 	--paper-purple-600: #8e24aa;
// 	--paper-purple-700: #7b1fa2;
// 	--paper-purple-800: #6a1b9a;
// 	--paper-purple-900: #4a148c;
// 	--paper-purple-a100: #ea80fc;
// 	--paper-purple-a200: #e040fb;
// 	--paper-purple-a400: #d500f9;
// 	--paper-purple-a700: #aa00ff;

// 	--paper-deep-purple-500: #673ab7;
// 	--paper-deep-purple-600: #5e35b1;
// 	--paper-deep-purple-700: #512da8;
// 	--paper-deep-purple-800: #4527a0;
// 	--paper-deep-purple-900: #311b92;
// 	--paper-deep-purple-a100: #b388ff;
// 	--paper-deep-purple-a200: #7c4dff;
// 	--paper-deep-purple-a400: #651fff;
// 	--paper-deep-purple-a700: #6200ea;

// 	--paper-indigo-600: #3949ab;
// 	--paper-indigo-700: #303f9f;
// 	--paper-indigo-800: #283593;
// 	--paper-indigo-900: #1a237e;
// 	--paper-indigo-a100: #8c9eff;
// 	--paper-indigo-a200: #536dfe;
// 	--paper-indigo-a400: #3d5afe;
// 	--paper-indigo-a700: #304ffe;

// 	--paper-blue-600: #1e88e5;
// 	--paper-blue-700: #1976d2;
// 	--paper-blue-800: #1565c0;
// 	--paper-blue-900: #0d47a1;
// 	--paper-blue-a100: #82b1ff;
// 	--paper-blue-a200: #448aff;
// 	--paper-blue-a400: #2979ff;
// 	--paper-blue-a700: #2962ff;

// 	--paper-light-blue-500: #03a9f4;
// 	--paper-light-blue-600: #039be5;
// 	--paper-light-blue-700: #0288d1;
// 	--paper-light-blue-800: #0277bd;
// 	--paper-light-blue-900: #01579b;
// 	--paper-light-blue-a100: #80d8ff;

// 	--paper-light-blue-a400: #00b0ff;
// 	--paper-light-blue-a700: #0091ea;

// 	--paper-cyan-500: #00bcd4;
// 	--paper-cyan-600: #00acc1;
// 	--paper-cyan-700: #0097a7;
// 	--paper-cyan-800: #00838f;
// 	--paper-cyan-900: #006064;
// 	--paper-cyan-a100: #84ffff;
// 	--paper-cyan-a200: #18ffff;
// 	--paper-cyan-a400: #00e5ff;

// 	--paper-teal-500: #009688;
// 	--paper-teal-600: #00897b;
// 	--paper-teal-700: #00796b;
// 	--paper-teal-800: #00695c;
// 	--paper-teal-900: #004d40;
// 	--paper-teal-a100: #a7ffeb;
// 	--paper-teal-a200: #64ffda;
// 	--paper-teal-a400: #1de9b6;

// 	--paper-green-200: #a5d6a7;
// 	--paper-green-300: #81c784;
// 	--paper-green-400: #66bb6a;
// 	--paper-green-500: #4caf50;

// 	--paper-green-700: #388e3c;
// 	--paper-green-800: #2e7d32;
// 	--paper-green-900: #1b5e20;
// 	--paper-green-a100: #b9f6ca;
// 	--paper-green-a200: #69f0ae;
// 	--paper-green-a400: #00e676;
// 	--paper-green-a700: #00c853;

// 	--paper-light-green-400: #9ccc65;
// 	--paper-light-green-500: #8bc34a;
// 	--paper-light-green-600: #7cb342;
// 	--paper-light-green-700: #689f38;
// 	--paper-light-green-800: #558b2f;
// 	--paper-light-green-900: #33691e;
// 	--paper-light-green-a100: #ccff90;
// 	--paper-light-green-a200: #b2ff59;
// 	--paper-light-green-a400: #76ff03;
// 	--paper-light-green-a700: #64dd17;

// 	--paper-lime-400: #d4e157;
// 	--paper-lime-500: #cddc39;
// 	--paper-lime-600: #c0ca33;
// 	--paper-lime-700: #afb42b;
// 	--paper-lime-800: #9e9d24;
// 	--paper-lime-900: #827717;
// 	--paper-lime-a100: #f4ff81;
// 	--paper-lime-a200: #eeff41;
// 	--paper-lime-a400: #c6ff00;
// 	--paper-lime-a700: #aeea00;

// 	--paper-yellow-400: #ffee58;
// 	--paper-yellow-500: #ffeb3b;
// 	--paper-yellow-600: #fdd835;
// 	--paper-yellow-700: #fbc02d;
// 	--paper-yellow-800: #f9a825;
// 	--paper-yellow-900: #f57f17;

// 	--paper-yellow-a200: #ffff00;
// 	--paper-yellow-a400: #ffea00;
// 	--paper-yellow-a700: #ffd600;

// 	--paper-amber-300: #ffd54f;
// 	--paper-amber-400: #ffca28;
// 	--paper-amber-500: #ffc107;
// 	--paper-amber-600: #ffb300;
// 	--paper-amber-700: #ffa000;
// 	--paper-amber-800: #ff8f00;
// 	--paper-amber-900: #ff6f00;
// 	--paper-amber-a100: #ffe57f;
// 	--paper-amber-a200: #ffd740;
// 	--paper-amber-a400: #ffc400;
// 	--paper-amber-a700: #ffab00;

// 	--paper-orange-300: #ffb74d;
// 	--paper-orange-400: #ffa726;
// 	--paper-orange-500: #ff9800;
// 	--paper-orange-600: #fb8c00;
// 	--paper-orange-700: #f57c00;
// 	--paper-orange-800: #ef6c00;
// 	--paper-orange-900: #e65100;
// 	--paper-orange-a100: #ffd180;
// 	--paper-orange-a200: #ffab40;
// 	--paper-orange-a400: #ff9100;
// 	--paper-orange-a700: #ff6500;

// 	--paper-deep-orange-400: #ff7043;
// 	--paper-deep-orange-500: #ff5722;
// 	--paper-deep-orange-600: #f4511e;
// 	--paper-deep-orange-700: #e64a19;
// 	--paper-deep-orange-800: #d84315;
// 	--paper-deep-orange-900: #bf360c;
// 	--paper-deep-orange-a100: #ff9e80;
// 	--paper-deep-orange-a200: #ff6e40;
// 	--paper-deep-orange-a400: #ff3d00;
// 	--paper-deep-orange-a700: #dd2c00;

// 	--paper-brown-500: #795548;
// 	--paper-brown-600: #6d4c41;
// 	--paper-brown-700: #5d4037;
// 	--paper-brown-800: #4e342e;
// 	--paper-brown-900: #3e2723;

// 	--paper-grey-600: #757575;
// 	--paper-grey-700: #616161;
// 	--paper-grey-800: #424242;
// 	--paper-grey-900: #212121;

// 	--paper-blue-grey-400: #78909c;
// 	--paper-blue-grey-500: #607d8b;
// 	--paper-blue-grey-600: #546e7a;

// 	--paper-blue-grey-800: #37474f;
// 	--paper-blue-grey-900: #263238;
// }


//pond visualization

  .pond-visualization{
	  margin-top: 20px;
	  border-radius: 2px;
	  .box {
		height: 20px;
		width: 20px;
		margin-bottom: 15px;
		border: none;
		border-radius: 3px;
		float: left;
        margin:1pt;
        // width:72pt;
        // height:72pt;
	  }
	  
	  .red {
		background-color: red;
	  }
	  .blue {
		background-color: blue;
	  }
	  .purple {
		background-color: purple;
	  }
	  .white {
		background-color: white;
		border: solid #e0e0e0 1px;
	  }
	  .green {
		background-color: green;
	  }
	  .yellow {
		background-color: yellow;
	  }

	  
	  .grey {
		background-color: #e0e0e0;
		border-radius: 6px;
	  }
	  
	  .green {
		background-color: green;
	  }
	  .pond-margin{
		margin-top: 10px ;

	  }
	  .max-width{
		  width: 50%;
		  display:inline-table
	  }
	  .align-left{
		float: left;  
	  }
	  .align-right{
		float: right;  
	  }
	  .display-pond{
		  display: flex;
	  }
	  .pond-outer-div {
		background-color: #e0e0e0;
		border-radius: 6px;
		margin: 1rem;
		// padding: 2rem 2rem;
		text-align: center;
		min-height: 125px;
		max-height: 125px;
		overflow-y: scroll;
	  }
	  .pond-inner-div {
		display: inline-block;
		// border: 1px solid red;
		padding: 1rem 1rem;
		vertical-align: text-top;
		width: 50%;
		// margin-right: 44px;
	  }
	  .rotate{
		transform: rotate(-90deg);
		position: absolute;
		width: 101px;
		height: 25px;
		text-align: center;
		left: -34px;
		margin-top: 62px;
	  }
	  .pond-header{
		  margin-left: 1rem;
	  }
	  .pond-header-flex{
		display: inline-block;
		width: 50%;
	  }
	  .tooltip-style{
		position: absolute;
		color: black;
		padding: 3px;
		width: 30px;
		text-align: center;
		transform: translate(-4%,-50%);
		border-radius: 5px;
		pointer-events: none;
		z-index: 1000;
	  }
  }
  .oval-box{
	// background: red;
    border: none;
    width: 20px;
    height: 10px;
    border-radius: 4px;
	margin-top: 5px;
  }
  .legend-inner-div{
	  display: flex;
  }
  .pond-lengend{
	  display: flex;
  }
  .legend-label{
	  margin-left: 5px;
	  margin-right: 5px;
  }
  .red {
	background-color: red;
  }
  .blue {
	background-color: blue;
  }
  .purple {
	background-color: purple;
  }
  .white {
	background-color: white;
	border: solid #e0e0e0 1px;
  }
  .green {
	background-color: green;
  }
  .yellow {
	background-color: yellow;
  }
  .blank{
	  background-color: #e0e0e0;
  }
  .legend-box-flex{
	display: flex;
	padding: 5px;
  }
  .legend-box{
	width: 50px;
    height: 50px;
    border-radius: 5px;
	margin-right: 5px;
	//   background-color: red;
  }
  .lengend-content{
	  display: block;
  }
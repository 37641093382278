// HEADER
.header-assignee-btn {
	background-color: #fff;
	color: #006241;
	margin-right: 1rem;
	height: 44px;
	.icon {
		padding: 9px 10px 6px;
		background-color: #ecf1f0;
		border-radius: 50%;
		margin-right: 6px;
		padding-top: 6px;
		float: left;
	}
	&:hover {
		background-color: rgba(0, 98, 65, 0.1) !important;
		color: #006241;
	}
}
.position-assignee-btn{
	display: inline-flex;
	align-items: center;
}
.header-order-btn {
	background-color: #fff;
	border: 1px solid #e1e8e6;
	color: #006241;
	margin-right: 1rem;
	height: 44px;
	.icon {
		padding: 9px 10px;
		background-color: #ecf1f0;
		border-radius: 50%;
		margin-right: 6px;
	}
	&:hover {
		background-color: rgba(0, 98, 65, 0.1) !important;
		color: #006241;
	}
}
.height-order-class {
	min-height: 44px !important;
	border-width: 2px !important;
	border-color: #e1e8e6 !important;
	box-shadow: none !important;
	&:hover {
		border-color: #e1e8e6 !important;
	}
}
.header-location-dropdown {
	width: 14rem;
	margin-right: 1rem;

	.header-react-select__control {
		min-height: 44px;
		border-width: 2px;
		border-color: #e1e8e6;
		box-shadow: none;
		&:hover {
			border-color: #e1e8e6;
		}
	}
	.header-react-select__indicator-separator {
		display: none;
	}
	.header-react-select__placeholder,
	.header-react-select__single-value {
		color: #212529;
		font-weight: 500;
	}
	.header-react-select__indicator.header-react-select__clear-indicator {
		padding: 0;
		cursor: pointer;
	}
	.header-react-select__indicator.header-react-select__dropdown-indicator {
		padding-left: 0;
		padding-right: 5px;
	}

	.header-react-select-common__control {
		min-height: 40px;
		//border-width: 2px;
		//border-color: #e1e8e6;
		//box-shadow: none;
		&:hover {
			border-color: #e1e8e6;
		}
	}

	.header-react-select-common__indicator-separator {
		display: none;
	}
	.header-react-select-common__placeholder,
	.header-react-select-common__single-value {
		color: #212529;
		font-weight: 400;
	}
	.header-react-select-common__indicator.header-react-select-common__clear-indicator {
		padding: 0;
		cursor: pointer;
	}
	.header-react-select-common__indicator.header-react-select-common__dropdown-indicator {
		padding-left: 0;
		padding-right: 5px;
	}
}

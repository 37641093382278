.customer-listing-col {
	&-name {
		flex: 2;
	}
	&-dc {
		flex: 2;
	}
	&-city {
		flex: 2;
	}
	&-actions {
		width: 120px;
		justify-content: flex-end;
		text-align: right;
	}
}

.customer-management-form {
	.outer-box {
		border-radius: 4.5px;
		border: 1px solid #e1e8e6;
		//overflow: hidden;
		padding: 1.5rem 1.5rem 1rem;
		margin-bottom: 1rem;
		background: unset;
	}
	.outer-box-view {
		border-radius: 4.5px;
		border: 1px solid #e1e8e6;
		padding: 2rem;
		margin-bottom: 1rem;
	}
	.border-box {
		border-radius: 4.5px;
		border: 1px dashed #e1e8e6;
		//overflow: hidden;
		//padding: 0.5rem;
		margin-bottom: 1rem;
	}
	.remove-link-btn {
		font-size: 13px;
		font-weight: 500;
		color: #6d6e71;
		.icon {
			font-size: 10px;
		}
		&:hover,
		&:active,
		&:focus {
			color: #6d6e71;
		}
	}
}

// DC View Page Title
.customer-view-title {
	font-weight: 900;
	margin: 20px 5px 25px;
	font-size: 18px;
}
@mixin reorderFormListItemStyle {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	color: #000000;
	min-height: 50px;
	background-color: #ecf1f0;
	border: 1px solid #ececec;
	margin-bottom: 10px;
}

.reorderForm {
	.sort-priority-container-child {
		@include reorderFormListItemStyle;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
	}
}
.dc-edit-headers {
	display: flex;
	justify-content: space-between;
}
// Manage DC Priority - Sortable List Item disappearing issue solved
.sortableHelper {
	z-index: 9999;
	@include reorderFormListItemStyle;
	box-shadow: 0 0px 8px 1px rgba(6, 101, 69, 0.3);
	cursor: crosshair;
}

.customer-pricing-table {
	width: 100%;
	margin-bottom: 1rem;
	box-shadow: 0 0 7px 1px #e1e8e6;
	border-radius: 10px;
	&-head {
		display: flex;
		background-color: #e1e8e6;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border: 1px solid #e1e8e6;
		color: #000000;
		font-weight: 500;
	}
	&-body {
		// display: flex;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border: 1px solid #e1e8e6;
		&-row {
			display: flex;
			border-bottom: 1px solid #e1e8e6;
			&:last-of-type {
				border: none;
			}
		}
	}
	.cpt {
		// All Cells
		&-head-name,
		&-head-pricing,
		&-head-week,
		&-body-name,
		&-body-pricing,
		&-body-week {
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			color: #000000;
			font-weight: 600;
			@media (max-width: 1040px) {
				font-size: 13px;
			}
			@media (max-width: 1000px) {
				font-size: 12px;
			}
			@media (max-width: 938px) {
				font-size: 11px;
			}
			&.view-mode {
				align-items: center;
			}
			.form-group {
				span.span-error,
				span.span-warning {
					font-size: 10px;
					position: relative;
					display: block;
					max-width: 4rem;
					bottom: auto;
					right: auto;
				}
			}
			.form-control {
				max-width: 3.21em;
				padding: 0 8px;
				text-align: center;
				height: 30px;
				min-height: unset;
				font-size: 13px;
				@media (max-width: 1000px) {
					font-size: 11px;
				}
				@media (max-width: 938px) {
					font-size: 10px;
					word-break: break-word;
				}
			}
		}

		// Head and Body Weeks Cells
		&-head-pricing,
		&-head-week,
		&-body-pricing,
		&-body-week {
			justify-content: center;
		}

		// Head
		&-head {
			&-name {
				flex: 2;
				padding-left: 20px;
				padding-right: 10px;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			&-pricing,
			&-week {
				flex: 1;
				padding-top: 20px;
				padding-bottom: 20px;
				&.view-mode {
					padding-left: 6px;
					padding-right: 6px;
				}
			}
			&-week {
				@media (max-width: 938px) {
					display: block;
					text-align: center;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		// Body
		&-body {
			&-name {
				flex: 2;
				padding-left: 20px;
				color: #006241;
				padding-top: 18px;
				word-break: break-word;
				padding-right: 10px;
				&.view-mode {
					padding-top: 10px;
					align-items: center;
					padding-bottom: 10px;
				}
			}
			&-pricing,
			&-week {
				flex: 1;
				padding: 13px 0;
				.form-group {
					margin-bottom: 0;
				}
				&.view-mode {
					font-weight: normal;
					padding-left: 6px;
					padding-right: 6px;
					word-break: break-word;
				}
			}
			&-pricing {
				background-color: #eeeeee47;
				border-left: 1px solid #e1e8e6;
				border-right: 1px solid #e1e8e6;
			}
		}
	}
}

// Cross Supply listing page
@mixin cs-listing-table-common-style {
	@media (max-width: 1440px) {
		font-size: 13px;
		&-header {
			font-size: 13px;
		}
	}
}

.cs-listing-col {
	&-transferId {
		flex: 1;
		@media (max-width: 1440px) {
			flex: none;
			width: 105px;
		}
		@include cs-listing-table-common-style;
	}
	&-sender {
		flex: 1;
		text-justify: left;
		@include cs-listing-table-common-style;
	}
	&-recipient {
		flex: 1;

		@include cs-listing-table-common-style;
	}
	&-sku {
		flex: 1 1;

		@include cs-listing-table-common-style;
	}
	&-date {
		flex: 1;
		@include cs-listing-table-common-style;
		&-header {
			overflow: unset !important;
		}
	}
	&-number {
		flex: 1;

		@include cs-listing-table-common-style;
		&-header {
			text-transform: none;
		}
	}

	&-status {
		flex: 1.5;
		@media (max-width: 900px) {
			flex: none;
			width: 115px;
		}
		@include cs-listing-table-common-style;
	}
	&-actions {
		width: 130px;
		justify-content: flex-end;
		text-align: right;
	}
}
// New Transfer table

.crossSupplyForm {
	.bright-farms-table .bright-farms-table-tbody .bright-farms-table-tr {
		min-height: 75px;
	}
	.bright-farms-table .bright-farms-table-th {
		padding: 10px 30px;
	}
	.bright-farms-table .bright-farms-table-tbody .bright-farms-table-tr .bright-farms-table-td:first-child {
		border-radius: 5px 0 0 5px;
		margin-right: 60px;
	}

	.form-group .custom-select-box [class*="-control"] {
		z-index: 1;
	}
	.form-group span.span-error,
	.form-group span.span-warning {
		left: 20px;
	}
}
.cs-board-sku {
	min-width: 110px;
	margin-left: -10px;
	padding-left: 0px;
	padding-right: 0px;
	position: absolute;

	.form-group .custom-select-box [class*="-control"] {
		max-width: 100px;
		margin-top: -20px;
	}
}

.cs-board-cases {
	max-width: 170px;
	position: absolute;
	margin-left: -60px;
	margin-top: -28px;

	.form-group.not-matching .form-control,
	.form-group.error .form-control,
	.form-group .form-control {
		margin-left: 53px;
		margin-bottom: 2px;
		margin-top: 8px;
	}
	.form-group span.span-error,
	.form-group span.span-warning {
		left: 100px;
	}
}

.cs-board-date {
	max-width: 150px;
	padding-right: 13px;
	margin-top: -20px;
	margin-left: 110px;
	position: absolute;
}

.cs-listing {
	&-header-location-dropdown .header-react-select__control {
		width: 200px;
	}
}

.cs-board-col {
	text-align: left;
}

.cs-board-col-actions {
	position: absolute;
	right: 10px;
	margin-right: 10px;
}

.product-variety-listing {
	color: black;
}
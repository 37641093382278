@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?s172jo");
	src: url("fonts/icomoon.eot?s172jo#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?s172jo") format("woff2"),
		url("fonts/icomoon.ttf?s172jo") format("truetype"), url("fonts/icomoon.woff?s172jo") format("woff"),
		url("fonts/icomoon.svg?s172jo#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-reset:before {
	content: "\e91f";
	color: #006241;
}
.icon-copy:before {
	content: "\e920";
	color: #006241;
}
.icon-cases-by-customer:before {
	content: "\e91e";
	color: #045a3d;
}
.icon-market:before {
	content: "\e928";
	color: #006241;
}
.icon-Inventory:before {
	content: "\e91a";
	color: #006241;
}
.icon-clock:before {
	content: "\e91b";
	color: #6d6e71;
}
.icon-cases-per-sku:before {
	content: "\e91c";
	color: #006241;
}
.icon-change-recipe:before {
	content: "\e91d";
	color: #006242;
}
.icon-reorder:before {
	content: "\e919";
	color: #006241;
}
.icon-menu:before {
	content: "\e900";
	color: #0e4a43;
}
.icon-select-assignee:before {
	content: "\e917";
	color: #006241;
}
.icon-upload-forecast:before {
	content: "\e929";
	color: #006242;
}
.icon-calendar:before {
	content: "\e915";
	color: #6d6e71;
}
.icon-repeat:before {
	content: "\e916";
	color: #006242;
}
.icon-info:before {
	content: "\e918";
}
.icon-total-cases-required:before {
	content: "\e921";
	color: #d8e7e6;
}
.icon-total-cases-packed:before {
	content: "\e922";
	color: #fad5ea;
}
.icon-cases-per-lab-hour:before {
	content: "\e923";
	color: #caecfa;
}
.icon-lbs-per-lab-hour:before {
	content: "\e924";
	color: #d9d9d9;
}
.icon-harvest-rate:before {
	content: "\e925";
	color: #e4efb9;
}
.icon-seeding-rate:before {
	content: "\e926";
	color: #fad7c7;
}
.icon-total-lbs-harvested:before {
	content: "\e927";
	color: #64bd56;
}
.icon-show:before {
	content: "\e912";
}
.icon-hide:before {
	content: "\e913";
}
.icon-angle-left:before {
	content: "\e901";
}
.icon-angle-right:before {
	content: "\e902";
}
.icon-angle-up:before {
	content: "\e903";
}
.icon-caret-down:before {
	content: "\e904";
}
.icon-caret-left:before {
	content: "\e905";
}
.icon-caret-right:before {
	content: "\e906";
}
.icon-caret-up:before {
	content: "\e907";
}
.icon-check:before {
	content: "\e908";
}
.icon-close:before {
	content: "\e909";
}
.icon-edit:before {
	content: "\e90a";
}
.icon-minus:before {
	content: "\e90b";
}
.icon-notification:before {
	content: "\e90c";
}
.icon-plus:before {
	content: "\e90d";
}
.icon-search:before {
	content: "\e90e";
}
.icon-trash:before {
	content: "\e90f";
}
.icon-upload:before {
	content: "\e910";
}
.icon-user:before {
	content: "\e911";
}
.icon-angle-down:before {
	content: "\e914";
}
.icon-shipping-icon:before {
	content: "\e92a";
}
.icon-shipped:before {
	content: "\e92b";
}
.icon-export:before {
	content: "\e92c";
}
.icon-printer:before {
	content: "\e954";
}

.accordion-table-body-toggler {
	min-width: unset;
	margin-top: 5px;
}
.audit-log {
	&-wrapper {
		display: flex;
		flex-direction: column;
		border: 1px solid #e1e8e6;
		padding: 2rem;
		border-radius: 8px;
		.custom-close {
			border: 1px solid #cccccc;
			padding: 9px;
			border-radius: 50%;
			font-size: 10px;
			background-color: #e1e8e6;
		}
	}
	&-body {
		display: flex;
		@media (max-width: 992px) {
			flex-direction: column;
		}
		&-list {
			flex: 3;
			max-height: 27rem;
			overflow-x: hidden;
			overflow-y: scroll;
			border: 1px solid #e1e8e6;
			box-shadow: inset 1px 1px 15px -8px #e1e8e6;
			&-item {
				display: flex;
				border-top: 0;
				padding: 1rem;
				font-size: 12.5px;
				// box-shadow: inset 1px 1px 15px -8px #e1e8e6;
				cursor: pointer;
				border-bottom: 1px solid #e1e8e6;
				border-right: 6px solid transparent;
				&:first-of-type {
					border-top: 0;
				}
				&:hover {
					color: #44a75d;
				}
				&.selected {
					background-color: rgba(225, 232, 230, 0.129);
					border-right: 6px solid rgba(0, 98, 65, 0.741);
				}
			}
		}
		&-view {
			flex: 2;
			box-shadow: inset 1px 1px 15px -8px #e1e8e6;
			border: 1px solid #e1e8e6;
			padding: 1rem 2rem;
			border-left: 0;
			max-height: 27rem;
			overflow-x: hidden;
			overflow-y: scroll;
			&-item {
				display: flex;
				border-bottom: 1px solid #e1e8e6;
				font-size: 12.5px;
				padding: 1rem 0;
				color: #6d6e71;
			}
		}
	}
}

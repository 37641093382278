.forecast-table-icon {
	font-size: 12px;
	// /* color: #2d7d63;
}

.forecast-table-wrapper {
	padding-left: 15px;
	// padding-right: 15px;
	margin-right: -5px;
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 20px;

	.total-txt {
		font-weight: 500;
		color: #212529;
	}
}

.forecast-fixed-column {
	// width: 200px;
	padding-top: 50px;
	position: relative;

	.forecast-table-head {
		// box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
		// box-shadow: -2px 0 5px -2px rgba(0, 0, 0, 0.1);
		box-shadow: -2px 0 5px -2px rgba(0, 0, 0, 0.1);
		border-radius: 10px 0 0 10px;
		margin-right: 0px;
		text-transform: capitalize;
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		min-height: 50px;
		background-color: #ecf1f0;
		border: 1px solid #ececec;
		border-right: none;
		position: relative;
		top: 0;
		z-index: 3;

		&.show-shadow {
			box-shadow: -3px 0 5px -2px rgba(0, 0, 0, 0.1), -1px 0 5px -2px rgba(0, 0, 0, 0.1) !important;
		}
	}

	.forecast-table-body-row {
		// box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		// box-shadow: -2px 0 5px -2px rgba(0, 0, 0, 0.1);
		box-shadow: -2px 0 5px -2px rgba(0, 0, 0, 0.1);

		border-radius: 50px;
		margin-right: 0px;
		transition: all 0.1s ease-in-out;
		min-height: 50px;
		background-color: #fff;
		border: 1px solid #ececec;
		border-right: none;
		border-radius: 10px 0 0 10px;
		color: #6d6e71;
		font-size: 14px;
		position: relative;
		font-weight: 400;
		&.show-shadow {
			box-shadow: -3px 0 5px -2px rgba(0, 0, 0, 0.1), 3px 0 5px -2px rgba(0, 0, 0, 0.1) !important;
		}
	}
}
.forecast-data-column {
	flex: 1;
	display: grid;
	padding-top: 50px;
	position: relative;

	.row {
		flex-wrap: nowrap;
	}

	.forecast-table-head {
		box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.1);
		border-radius: 0 10px 10px 0;
		text-transform: capitalize;
		border-left: none !important;
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		min-height: 50px;
		background-color: #ecf1f0;
		border: 1px solid #ececec;
		margin-left: 0;
		margin-right: 5px;
		position: sticky;
		top: 0;
		z-index: 2;

		.forecast-table-col {
			min-width: 200px;
		}
	}

	.forecast-table-body-row {
		box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.1);
		border-left: none !important;
		transition: all 0.1s ease-in-out;
		min-height: 50px;
		background-color: #fff;
		border: 1px solid #ececec;
		border-radius: 0 10px 10px 0;
		color: #6d6e71;
		font-size: 14px;
		font-weight: 400;
		margin-left: 0;
		margin-right: 5px;

		.forecast-table-col {
			min-width: 200px;
		}
	}
}

.indiana-scroll-container--dragging {
	cursor: -webkit-grabbing !important;
	cursor: grabbing !important;
}

.forecast-data-column-wrapper {
	// width: calc(100% - 200px);
	overflow-x: auto;
	margin-right: -25px;
	padding-bottom: 10px;
	cursor: -webkit-grab;
	cursor: grab;
}
.forecast-fixed-column-wrapper {
	padding-bottom: 10px;
}

.forecast-table-body {
	margin-top: 10px;
}

.forecast-table-body-row > .col,
.forecast-table-head > .col {
	display: flex;
	align-items: center;
}

.cont-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.forecast-table-col > .form-group {
	margin-bottom: 0 !important;
}

.forecast-table-col > .checkboxWrapper > .checkboxMark.normal {
	top: -8px;
}

.forecast-data-column-wrapper::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	-webkit-appearance: none;
}

.forecast-data-column-wrapper::-webkit-scrollbar-thumb {
	background: #cccccc;
	border-radius: 5px;
}

.forecast-data-column-wrapper::-webkit-scrollbar-track {
	border-radius: 5px;
}

.scroll-shadow {
	width: 0px;
	position: absolute;
	height: 48px;
	right: -1px;
	box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.1);
}

.table-input {
	width: 80px;
	height: 33px;
}

.input-error {
	border: 1px solid rgba(244, 67, 54, 0.5);
}

.error-string {
	font-size: 12px;
	position: absolute;
	top: -40px;
	right: 15px;
	color: rgba(244, 67, 54, 0.8);
}

.save-btn {
	width: 80px;
}

.sticky-head {
	position: absolute;
	display: grid;
	width: 100%;
	margin-right: 10px;
	top: 0;
}

.forecast-table-btn-row {
	margin-bottom: 25px;
}

.show-shadow-row {
	position: absolute;
	height: 50px;
	box-shadow: 3px 0 4px -2px rgba(0, 0, 0, 0.1), 3px 0 5px -2px rgba(0, 0, 0, 0.1) !important;
	// z-index: ;
	right: -0;
	top: 0;
	border-radius: 10px 0 0 10px;

	z-index: 1;
	width: 6px;
	display: none;

	&.shadow-head {
		z-index: 4;
	}
}

.orders-listing-col {
	&-checkbox {
		width: 35px;
		height: 29px;
		&-header {
			width: 35px;
			height: auto;
		}
	}
	&-id {
		// flex: 1.2;
		width: 10%;
	}
	&-name {
		// flex: 2;
		width: 12%;
	}
	&-dc {
		// flex: 1.8;
		width: 9%;
	}

	&-sku {
		// flex: 1;
		width: 13%;
	}
	&-skus {
		// flex: 1;
		width: 14%;
	}
	&-ord {
		// flex: 1.8;
		width: 11%;
	}
	&-dod {
		// flex: 1.8;
		width: 11%;
	}

	&-req {
		// flex: 1.4;
		width: 8%;
	}
	&-status {
		// flex: 1;
		width: 9%;
	}
	&-actions {
		width: 100px;
		// justify-content: flex-end;
		justify-content: center;
		text-align: right;
	}
	&-cases-packed {
		// flex: 1.5;
		width: 10%;
	}
	@media screen and (max-width: 1399px) {
		// &-checkbox {
		// 	width: 35px;
		// 	height: 29px;
		// 	&-header {
		// 		width: 35px;
		// 		height: auto;
		// 	}
		// }
		// &-id {
		// 	// flex: 1.2;
		// 	width: 10%;
		// }
		// &-name {
		// 	// flex: 2;
		// 	width: 14%;
		// }
		// &-name-header {
		// 	// flex: 2;
		// 	width: 16%;
		// }
		// &-dc {
		// 	// flex: 1.8;
		// 	width: 8%;
		// }
		// &-dc-header {
		// 	// flex: 1.8;
		// 	width: 8%;
		// }
		// &-sku {
		// 	// flex: 1;
		// 	width: 8%;
		// }
		// &-sku-header {
		// 	// flex: 1;
		// 	width: 9%;
		// }
		// &-ord {
		// 	// flex: 1.8;
		// 	width: 15%;
		// }
		// &-ord-header {
		// 	// flex: 1.8;
		// 	width: 14%;
		// }
		// &-dod {
		// 	// flex: 1.8;
		// 	width: 12%;
		// }
		// &-dod-header {
		// 	// flex: 1.8;
		// 	width: 13%;
		// }

		// &-req {
		// 	// flex: 1.4;
		// 	width: 11%;
		// }
		// &-req-header {
		// 	// flex: 1.4;
		// 	width: 12%;
		// }
		// &-status {
		// 	// flex: 1;
		// 	width: 10%;
		// }
		// &-actions {
		// 	width: 100px;
		// 	justify-content: flex-end;
		// 	text-align: right;
		// }
		// &-cases-packed {
		// 	// flex: 1.5;
		// 	width: 10%;
		// }
		// &-cases-packed-header {
		// 	// flex: 1.5;
		// 	width: 11%;
		// }
	}
}

.outer-box {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	//overflow: hidden;
	padding: 1rem;
	margin-bottom: 1rem;
}
.outer-box-view {
	border-radius: 4.5px;
	border: 1px solid #e1e8e6;
	padding: 2rem;
	margin-bottom: 1rem;
}
.remove-link-btn {
	font-size: 13px;
	font-weight: 500;
	color: #6d6e71;
	.icon {
		font-size: 10px;
	}
	&:hover,
	&:active,
	&:focus {
		color: #6d6e71;
	}
}

.sku-board-outer > .sku-board-row {
	background-color: #f2f5f4;
	padding: 10px;
	margin: 25px 0 0 0;
	border-radius: 5px;
	&:first-child {
		margin: 10px 0 0 0;
	}
}

.outer-box {
	margin-top: 10px;
}

.inventory-btn,
.cases-btn {
	background: #f7f9f8;
	border: 1px solid #ecf1f0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	line-height: 45px;
	margin-right: 20px;

	& i {
		font-size: 18px;
	}
}

// Order Packaging view page
.op-view {
	&-content {
		border: 1px solid #e1e8e6;
		border-radius: 4.5px;
		padding: 1rem 2rem;
		margin-bottom: 1.8rem;
		&.status-bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.status-bar-title {
				font-weight: bold;
				margin: auto 0;
			}
			.status-bar-subtitle {
				font-size: 12px;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}
		.btn-sku-change {
			position: relative;
			top: -10px;
			right: -4px;
			background-color: #fff;
			border: 1px solid #e1e8e6;
			&.mr-sku-adjust {
				right: 12px;
			}
		}
	}
	&-summary {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 1rem 2rem;
		border: 1px solid #e1e8e6;
		border-radius: 4.5px;
		background-color: rgba(225, 232, 230, 0.5);
		height: 100%;
		.text-label {
			font-size: 15px;
		}
	}
	&-footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;
		padding-bottom: 2rem;
	}
}
.op-mb {
	margin-bottom: 1.8rem;
}
.sku-composition-wrapper {
	.custom-select-box {
		width: 100%;
		[class*="-control"] {
			background-color: var(--color-white);
			border-radius: 5px;
			border: 1px solid var(--color-light-gray);
			[class*="-placeholder"] {
				color: #afafaf;
				padding-left: 5px;
				font-size: 14px;
				font-weight: 400;
			}
			[class*="-indicatorSeparator"] {
				display: none;
			}
			[class*="-indicatorContainer"] {
				display: none;
			}
		}
	}
	.react-datepicker-wrapper {
		width: 100%;
	}
	.btn-link-grey {
		color: #787b7c;
	}
}
//INVENTORY TABLE STYLES

.modal-table-icon {
	font-size: 12px;
	// /* color: #2d7d63;
}

.modal-table-wrapper {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 10px;

	.casePerSku{
		padding-left: 0px !important;
	padding-right: 0px !important;
	}
	.error-text {
		font-size: 11px;
		position: absolute;
		bottom: -25px;
		right: 15px;
		color: rgba(244, 67, 54, 0.8);
	}
}

.modal-table-head {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 700;
	color: #000000;
	min-height: 50px;
	background-color: #ecf1f0;
	border: 1px solid #ececec;
}

.modal-table-body-row {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	transition: all 0.1s ease-in-out;
	min-height: 50px;
	background-color: #fff;
	border: 1px solid #ececec;
	border-radius: 10px;
	color: #6d6e71;
	font-size: 14px;
	font-weight: 400;
}
.modal-table-body {
	margin-top: 10px;
}

.modal-table-body-row > .col,
.modal-table-head > .col {
	display: flex;
	align-items: center;
}

.cont-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.modal-table-col > .form-group {
	margin-bottom: 0 !important;
}

.modal-table-col > .checkboxWrapper > .checkboxMark.normal {
	top: -8px;
}

//INVENTORY TABLE STYLES ENDS

// CASES PER SKU
.modal-sku-table {
	&-col1 {
		flex: 2;
	}
	&-col2 {
		flex: 2;
	}
	&-col3 {
		flex: 2;
	}
}

.search-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 15px;
}

.search-cancel-btn {
	border: none;
	position: absolute;
	right: 5px;
	border-radius: 50%;
	background-color: #ecf1f0;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	transform: translate(0, -50%);
	cursor: pointer;
}

.sku-input {
	height: 30px;
	padding-right: 40px;
}
.no-results-found {
	font-size: 14px;
}

.cases-by-customer-section {
	margin-top: 15px;
}
.cases-by-customer-modal {
	.modal-dialog .modal-content {
		width: 690px !important;
	}
	@media (min-width: 576px) {
		.modal-dialog {
			max-width: none;
		}
	}
	@media (max-width: 690px) {
		.modal-dialog .modal-content {
			width: 100vw !important;
		}
	}
	.container {
		max-width: none;
	}
}

// @media (min-width: 576px) {
// 	.modal-dialog {
// 		/* max-width: 500px; */
// 		margin: 1.75rem auto;
// 	}
// }

.update-cases-btn {
	color: #6d6e71;
	background-color: #ecf1f0;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	width: 125px;
	border-radius: 5px;

	&:hover,
	&:focus,
	&:active {
		background-color: #ecf1f0 !important;
		color: #6d6e71 !important;
	}
	i {
		font-size: 13px;
		position: relative;
		top: -1px;
	}
}

.order-btn {
	padding: 0 10px;
}

.header-location-dropdown {
	margin-right: 20px !important;
}
.total_required_cases {
	padding: 0;
}
.total_required_cases_title::before,
.total_possible_cases_title::before {
	/* add the new bullet point */
	display: inline-block;
	content: "";
	-webkit-border-radius: 0.375rem;
	border-radius: 0.375rem;
	height: 0.6rem;
	width: 0.6rem;
	margin-right: 0.5rem;
	background-color: #e1e8e6;
}
.total_required_cases_title,
.total_possible_cases_title {
	color: #787b7c;
	font-size: 0.9rem;
	font-weight: 500;
}
.total_required_cases_value,
.total_possible_cases_value {
	padding-left: 20px;
	font-weight: 700;
}

.orders-listing {
	padding-bottom: 10px;
	// padding-left: 10px;
	// padding-right: 10px;
	// margin-left: -10px;
	overflow: auto;
	z-index: 10;
	&::-webkit-scrollbar {
		height: 7px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #cccccc;
	}
	.bright-farms-table {
		width: 1400px;
	}
}

.orders-page {
	.orders-daily-task-dropdown {
		.dropdown-menu {
			height: 341px;
			overflow-y: auto;

			&::-webkit-scrollbar {
				height: 7px;
				width: 6px;
			}
			/* Track */
			&::-webkit-scrollbar-track {
				border-radius: 10px;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #cccccc;
				border-radius: 10px;
			}
			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #cccccc;
			}
		}
	}
}
.buttonCenter{
	display: contents;
}
.add-inventory-button{
	margin-right: 1% !important;
}
.add-inventory-button1{
	margin-right: 4% !important;
}
.checkbox-input{
	top: -8px !important;
	left: -6px !important;
}
.sku-name{
	padding-bottom: 14px;
    font-weight: 500
}
.errorButton{
    top: 17px !important;
    right: 0 !important;
}
.inventory-tooltip{
	width: 100%;
    margin-left: 76%;
    position: absolute;
    margin-top: -40px;
    width: 100%;
    margin-left: 76%;
}
.tooltip{
	background-color: #fff !important;
    border: none !important;
    // border-radius: none;
    // font-size: 14px;
    // font-weight: bold;
}
.inventory_margin{
	margin-left: 18px;
}
.add-inventory-display{
	display: flex;
    width: 377px;
}
@keyframes table-anim {
	from {
		transform: scale(0.98, 0.98);
		opacity: 0;
	}
	to {
		transform: scale(1, 1);
		opacity: 1;
	}
}
.bright-farms-table {
	display: flex;
	width: 100%;
	flex-direction: column;
	animation: table-anim 0.4s ease-in-out;
	.bright-farms-table-col-group {
		display: table-column-group;
		.bright-farms-table-col {
			display: table-column;
		}
	}
	.bright-farms-table-thead {
		margin-bottom: 10px;
		// display: flex;
		//font-weight: 500 !important;
		// flex: 1;
		// min-height: 53px;
		// background-color: #ecf1f0;
		// border: 1px solid #ececec;
		// font-size: 12px;
		// letter-spacing: 0px;
		// color: #000;
		span {
			font-weight: 500 !important;
		}
		.bright-farms-table-tr {
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
			border-radius: 10px;
			text-transform: capitalize;
			font-size: 14px;
			font-weight: 700;
			color: #000000;
			min-height: 50px;
			background-color: #ecf1f0;
			border: 1px solid #ececec;
			.bright-farms-table-th:first-child {
				border-radius: 5px 0 0 5px;
			}
			.bright-farms-table-th:last-child {
				border-radius: 0 5px 5px 0;
			}
			.icon-angle-down {
				font-size: 10px;
			}
			.sortable {
				cursor: pointer;
				// text-align: center;
				.icon-search {
					color: #828987;
					font-size: 13px;
					position: relative;
					top: 2px;
				}

				.icon:not(.icon-search):not(.icon-close):not(.icon-angle-down) {
					margin-right: 8px;
					display: inline-block;
					width: 10px;
					height: 10px;
					position: relative;
					&:before,
					&:after {
						width: 0px;
						height: 0px;
						content: "";
						display: block;
						position: absolute;
						left: 0px;
						border: 4px solid transparent;
					}
					&:before {
						top: -4px;
					}
					&:after {
						top: 8px;
					}
					&.icon-sort {
						&:before {
							border-bottom-color: #ccc;
						}
						&:after {
							border-top-color: #ccc;
						}
					}
					&.icon-sort-asc {
						&:before {
							border-bottom-color: var(--color-theme);
						}
						&:after {
							border-top-color: #ccc;
						}
					}
					&.icon-sort-desc {
						&:before {
							border-bottom-color: #ccc;
						}
						&:after {
							border-top-color: var(--color-theme);
						}
					}
				}
			}
		}
	}
	.bright-farms-table-tbody {
		display: flex;
		flex-direction: column;
		.bright-farms-table-tr {
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			transition: all 0.1s ease-in-out;
			min-height: 50px;
			background-color: #fff;
			border: 1px solid #ececec;
			border-radius: 10px;
			color: #6d6e71;
			font-size: 14px;
			font-weight: 400;
			&:hover {
				box-shadow: 0 2px 4px rgba(0, 98, 65, 0.28);
			}
			.bright-farms-table-td:first-child {
				border-radius: 5px 0 0 5px;
			}
			.bright-farms-table-td:last-child {
				border-radius: 0 5px 5px 0;
			}
		}
	}
	.bright-farms-table-tr {
		display: flex;
		flex: 1;
		//margin: 0 0 0 0;
		align-items: center;
	}
	.bright-farms-table-th {
		display: flex;
		padding: 15px 6px;
		// padding: 10px 15px;
		letter-spacing: 0px;

		&.break-word {
			white-space: inherit !important;
		}
	}
	.bright-farms-table-td {
		display: block;
		padding: 5px 15px;
		.custom-toggle-btn {
			vertical-align: middle;
		}
		.icon {
			color: var(--color-theme);
			font-size: 12px;
		}
		.btn-sm {
			background-color: #ecf1f0;
		}
	}
	.bright-farms-table-th,
	.bright-farms-table-td {
		&:not(.nowrap) {
			// font-size: 12px !important;
			// white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.bright-farms-table-td-placeholder {
		padding: 8px;
		border-radius: 3px;
		background-color: rgba(0, 0, 0, 0.06);
		display: block;
	}
	.bright-farms-table-column-filter-icon {
		margin-left: 10px;
	}
	.bright-farms-table-column-filter-wrap {
		display: flex;
		align-items: center;
		position: relative;
		.form-control {
			width: 100%;
			padding-right: 30px;
			height: 30px;
			margin: -5px 0;
		}
		.bright-farms-table-column-filter-button {
			border: none;
			position: absolute;
			right: 5px;
			border-radius: 50%;
		}
	}
}

.bright-farms-pagination {
	display: flex;
	width: 100%;
	align-items: center;
	font-weight: 500;
	font-size: 12px;
	color: #666;
	.bright-farms-pagination-one {
		flex: 1;
	}
	.bright-farms-pagination-two {
		flex: 1;
		text-align: center;
	}
	.bright-farms-pagination-three {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		.pagination-btn {
			padding: 0;
			cursor: pointer;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			text-align: center;
			line-height: 32px;
			&.current {
				background-color: rgba(0, 0, 0, 0.06);
			}
			&.disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
}
.table-loader {
	text-align: center;
	background-color: rgba(0, 0, 0, 0.02);
	padding: 10px;
	border-radius: 10px;
	.loading-logo {
		width: 200px;
		display: block;
		margin: 10px auto;
		img {
			width: 200px;
		}
	}
	.loading-anim {
		width: 200px;
		display: block;
		margin: 0 auto 10px auto;
		height: 4px;
		border-radius: 2px;
		background-color: #dbdddd;
		overflow: hidden;
		position: relative;
		&:after {
			content: "";
			width: 30px;
			height: 4px;
			border-radius: 4px;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			background-color: #c9dd74;
			animation: loading-anim 1s ease-in-out infinite;
		}
	}
}

@keyframes loading-anim {
	from {
		left: -30px;
		width: 30px;
	}
	to {
		left: 200px;
		width: 100px;
	}
}
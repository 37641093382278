.boards-listing-col {
	&-name {
		flex: 3;
	}
	&-dimension {
		flex: 2;
	}
	&-furrow {
		flex: 2;
	}
	&-actions {
		width: 120px;
		justify-content: flex-end;
		text-align: right;
	}
}

.bright-farms-session-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(../../assets/login-bg.png);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-color: #ecf1f0;
	.session-content-loader {
		height: unset !important;
		.session-logo {
			margin: 0 0 30px 0;
			text-align: center;
			img {
				max-width: 180px;
			}
		}
		.sign-in-wrap {
			width: 350px;
			margin: 0 0 30px 0;
			background-color: var(--color-white);
			padding: 30px;
			border-radius: 20px;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
			h1 {
				color: var(--color-theme);
				margin: 5px 0 0 0;
			}
		}
	}

	.forgot-password {
		a {
			color: #6d6e71;
			&:hover {
				color: var(--color-theme);
				text-decoration: none;
			}
		}
		font-size: 12px;
		font-weight: 500;
	}
}
